import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { showNotification } from 'state/ui/notifications/notificationsSlice';
import {
  setCampaignRewards,
  setIsRewardCreated,
  setResetVoucherCodes,
  setRewardsVoucherCodesFieldValue
} from '../../../../state/campaign/campaignSlice';
import { generateCodes } from '../../../../api/campaignApi';
import styles from './PartnerPortal.module.scss';
import { Formik } from 'formik';
import { forEach, find, cloneDeep } from 'lodash';

export default function PartnerPortal(): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState('');
  const [busy, setBusy] = useState(false);

  const rewards = useSelector<RootState, any | null>((state) => state.campaign.campaign.rewards);
  const isRewardCreated = useSelector<RootState, boolean>((state) => state.campaign.isRewardCreated);
  const rewardId = useSelector<RootState, string | null>((state) => state.campaign.rewardId);
  const campaign = useSelector<RootState, any | null>((state) => state.campaign);
  const rewardsValues = useSelector<RootState, { reward_name: string }>((state) => state.campaign.rewardsSettings.values);
  const voucherCodesvalues = useSelector<RootState, { [key: string]: any }>((state) => state.campaign.rewardVoucherCodes.values);

  const reward = find(campaign && campaign.campaign && campaign.campaign.rewards, (r: any) => {
    return r.reward_id === campaign.rewardId;
  }) || {};
  const partner = find(campaign && campaign.campaign && campaign.campaign.partners, (p: any) => {
    return p.partner_id === reward.partner_id;
  }) || {};

  const [preservedData, setPreservedData] = useState(voucherCodesvalues);

  useEffect(() => {
    setPreservedData(voucherCodesvalues)
  }, [voucherCodesvalues])

  const preserveCampaign = (key: string, value: string[] | number | boolean) => {
    const newData = cloneDeep(preservedData);
    newData[key] = value;
    setPreservedData(newData);
  }

  useEffect(() => {
    if (preservedData.quantity && +preservedData.quantity > 1000) {
      setErrorMessage('Number of codes should not be greater than 1000.');
    } else {
      setErrorMessage('');
    }
  }, [preservedData.quantity])

  useEffect(() => {
    if (isRewardCreated) {
      dispatch(setIsRewardCreated(false));
      const pathArray = location.pathname.split('/');
      if (rewardId) {
        if (pathArray[pathArray.length - 3] === 'edit') {
          const pathArray = location.pathname.split('/');
          pathArray[pathArray.length - 2] = rewardId;
          history.push(pathArray.join('/'));
        } else {
          history.push(`./edit/${rewardId}/editRewards`);
        }
      }
    }
  }, [isRewardCreated, rewardId])

  const submitData = (values: any) => {
    forEach(preservedData, (value: any, key) => {
      if (value || (typeof value === 'boolean' && value === false)) {
        dispatch(setRewardsVoucherCodesFieldValue({
          field: key,
          value
        }))
      }
    })

    const params = {
      campaign_id: reward.campaign_id || '',
      organisation_id: campaign.campaign.licensor_organisation_id || '',
      reward_id: reward.reward_id || '',
      reward_name: reward.reward_name || '',
      reward_title: reward.reward_attributes.en.reward_title || '',
      partner_id: reward.partner_id || '',
      partner_name: partner.name || '',
      quantity: values.quantity || '',
      not_visible_in_email: values.not_visible_in_email || false
    }

    setBusy(true);

    generateCodes(params).then(() => {
      const newRewards = rewards?.map((item: any) =>
        item.reward_id === rewardId
          ? { ...item, win_max: values.quantity }
          : item);
      dispatch(setCampaignRewards(newRewards));
      dispatch(showNotification(`${values.quantity} codes were generated successfully!`));
      setBusy(false);
      dispatch(setResetVoucherCodes());
    }, () => {
      setErrorMessage('Oops! Something went wrong. Please try again.')
      setBusy(false);
    })
  }

  return (
    <Formik
      initialValues={voucherCodesvalues}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};
        if (!values.quantity) {
          errors.quantity = 'Number of codes cannot be empty';
        }
        return errors;
      }}
      onSubmit={(values) => submitData(values)}
    >
      {({ values, errors, handleSubmit, handleChange }) => (
        <form
          className={styles.root}
          onSubmit={handleSubmit}
        >
          <div className={styles.formInputContainer}>
            <label className={styles.inputLabel}>
              Reward Name
            </label>
            <div className={styles.textInput}>
              {rewardsValues.reward_name}
            </div>
          </div>

          <div className={styles.form}>
            <div className={styles.inputHolderGrow}>
              <p className={styles.label}>Number of codes</p>
              <Input
                type="number"
                name="quantity"
                min={1}
                value={preservedData.quantity}
                onChange={(e) => {
                  handleChange(e);
                  preserveCampaign('quantity', e.target.value)
                }}
                onKeyDown={(e) => ['-','+'].includes(e.key) && e.preventDefault()}
                className={styles.bigInput}
                error={errors.quantity ? (errors.quantity as string) : null}
              />
              {!!errorMessage && (
                <p className={styles.error}>{errorMessage}</p>
              )}
            </div>
            <div className={styles.checkboxInput}>
              <label>
                <input
                  type="checkbox"
                  name="not_visible_in_email"
                  value={values?.not_visible_in_email}
                  checked={!!values.not_visible_in_email}
                  onChange={(e) => {
                    handleChange(e);
                    preserveCampaign('not_visible_in_email', e.target.checked)
                  }}
                />
                <p className={styles.label}>Not visible in email</p>
              </label>
            </div>
          </div>

          <Button submit
            size="large"
            type="action"
            className={styles.submit}
            disabled={!!busy || !!errorMessage}
          >
            {!!busy && (<div className={styles.loader}></div>)}
            Generate codes
          </Button>
        </form>
      )}
    </Formik>
  );
}
