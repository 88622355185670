import { FILTER_TYPES } from 'app/components/Table/tableConstants';
import { RootState } from 'app/store';
import { doesValuePassFilter, getRewardStatus } from 'app/utils/helpers';
import { rewardTypes } from 'constants/rewardConstants';
import parseISO from 'date-fns/parseISO';
import { createSelector } from 'reselect';

const getPartners = (state: RootState) => (state.campaign.campaign || {}).partners;
const getPartnersTableSort = (state: RootState) => state.campaignDetailsPage.partnersTableSort;
const getPartnersTablePage = (state: RootState) => state.campaignDetailsPage.partnersTablePage;
const getPartnersTableFilters = (state: RootState) => state.campaignDetailsPage.partnersTableFilters;
const getPartnersRowsPerPage = (state: RootState) => state.campaignDetailsPage.partnersRowsPerPage;

const getRewards = (state: RootState) => state.campaign.campaign.rewards;
const getRewardsTableSort = (state: RootState) => state.campaignDetailsPage.rewardsTableSort;
const getRewardsTablePage = (state: RootState) => state.campaignDetailsPage.rewardsTablePage;
const getRewardsTableFilters = (state: RootState) => state.campaignDetailsPage.rewardsTableFilters;
const getRewardsRowsPerPage = (state: RootState) => state.campaignDetailsPage.rewardsRowsPerPage;

const getMetrics = (state: RootState) => state.campaign.metrics;
const getMetricsTableSort = (state: RootState) => state.campaignDetailsPage.metricsTableSort;
const getMetricsTablePage = (state: RootState) => state.campaignDetailsPage.metricsTablePage;
const getMetricsTableFilters = (state: RootState) => state.campaignDetailsPage.metricsTableFilters;
const getMetricsRowsPerPage = (state: RootState) => state.campaignDetailsPage.metricsRowsPerPage;

const getShortcodes = (state: RootState) => state.campaign.shortcodes;
const getShortcodesTableSort = (state: RootState) => state.campaignDetailsPage.shortcodesTableSort;
const getShortcodesTablePage = (state: RootState) => state.campaignDetailsPage.shortcodesTablePage;
const getShortcodesTableFilters = (state: RootState) => state.campaignDetailsPage.shortcodesTableFilters;
const getShortcodesRowsPerPage = (state: RootState) => state.campaignDetailsPage.shortcodesRowsPerPage;

export const getPartnersTableData = createSelector([getPartners], (partners) => {
    const partnersTableData = partners?.map(
        ({
            created_at,
            partner_id,
            name,
            support_email,
            isNew
        }: any) => ({
            created_at,
            partner_id,
            name,
            support_email,
            isNew
        })
    );
    
    return partnersTableData;
});

export const getRewardsTableData = createSelector([getPartners, getRewards], (partners, rewards) => {
    const rewardsTableData = rewards.map(
        ({
            start_at,
            end_at,
            daily_limit,
            reward_id,
            reward_name,
            partner_id,
            reward_probability,
            target_variant,
            win_count,
            win_count_today,
            win_max,
            reward_value,
            target_countries,
            is_enabled,
        }: any) => ({
            status: getRewardStatus(start_at, end_at, daily_limit, win_count_today, is_enabled, win_max, win_count),
            reward_id,
            start_at,
            end_at,
            daily_limit,
            reward_name,
            partner_name: (partners.find((p: any) => p.partner_id === partner_id) || {}).name,
            reward_probability,
            reward_value,
            win_count: +win_count,
            win_count_today: win_count_today,
            win_max: +win_max,
            target_variant,
            target_countries,
        })
    );
    return rewardsTableData;
});

export const getMetricsTableData = createSelector([getMetrics], (metrics) =>
    metrics.map((metricTableEntry: any) => ({
        ...metricTableEntry,
        created_at: parseISO(metricTableEntry.created_at),
    }))
);

export const getShortcodesTableData = createSelector([getShortcodes], (shortcodes) => {
    const shortcodesTableData = shortcodes.map(
        ({
            created_at,
            short_code,
            short_code_url,
            redirect_url,
            ignore_campaign_filter,
            notes,
            short_code_id
        }: any) => ({
            created_at,
            short_code,
            short_code_url,
            redirect_url,
            ignore_campaign_filter,
            notes,
            short_code_id
        })
    );
    return shortcodesTableData;
});

const sortFunc = (tableData: any, tableSort: any) => {
    const { field, direction } = tableSort;
    if (field && direction) {
        tableData = [...tableData].sort((a: any, b: any) => {
            const keyA = field === 'win_max'
                ? a.win_max - a.win_count
                : field === 'reward_value'
                    ? rewardTypes?.find(item => item.value === a.reward_value)?.label
                    : field === 'target_countries'
                        ? a[field]?.[0]
                        : field === 'created_at'
                            ? new Date(a.date)
                            : a[field]

            const keyB = field === 'win_max'
                ? b.win_max - b.win_count
                : field === 'reward_value'
                    ? rewardTypes?.find(item => item.value === b.reward_value)?.label
                    : field === 'target_countries'
                        ? b[field]?.[0]
                        : field === 'created_at'
                            ? new Date(b.date)
                            : b[field]

            const [aNormalized, bNormalized] =
                typeof keyA === 'string' ? [keyA?.toLowerCase(), keyB?.toLowerCase()] : [keyA, keyB];

            const sortDir = keyA instanceof Date ? -1 : 1;
            const compareResult =
                aNormalized > bNormalized || bNormalized === null || bNormalized === undefined ? sortDir : -sortDir;
            return direction === 'ASC' ? compareResult : -compareResult;
        });
    }
    return tableData && [...tableData];
};

const filterFunc = (tableData: any, filters: any) =>
    tableData.filter((row: any) =>
        Object.entries(filters).every(([field, filter]: [string, any]) => {
            let res = true;
            if (filter.value) {
                if (filter.type === FILTER_TYPES.TEXT) {
                    res = doesValuePassFilter(row[field], filter.value);
                }
                if (filter.type === FILTER_TYPES.SELECT) {
                    res =
                        (typeof row[field] === 'number' ? row[field] > -1 : row[field]) &&
                        (filter.value.length === 0 ||
                            filter.value.some((filterValue: string) => doesValuePassFilter(row[field], filterValue)));
                }
            }
            return res;
        })
    );

const pageFunc = (rewardsTableData: any, page: number, rowsPerPage: any) => {
    return rowsPerPage > 0
        ? rewardsTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rewardsTableData;
};

export const getPartnersTableDataFiltered = createSelector([getPartnersTableData, getPartnersTableFilters], filterFunc);
export const getPartnersTableDataSorted = createSelector([getPartners, getPartnersTableSort], sortFunc);
export const getPartnersTableDataSortedPaged = createSelector(
    [getPartnersTableDataSorted, getPartnersTablePage, getPartnersRowsPerPage],
    pageFunc);

export const getRewardsTableDataFiltered = createSelector([getRewardsTableData, getRewardsTableFilters], filterFunc);
export const getRewardsTableDataSorted = createSelector([getRewardsTableDataFiltered, getRewardsTableSort], sortFunc);
export const getRewardsTableDataSortedPaged = createSelector(
    [getRewardsTableDataSorted, getRewardsTablePage, getRewardsRowsPerPage],
    pageFunc);

export const getMetricsTableDataFiltered = createSelector([getMetricsTableData, getMetricsTableFilters], filterFunc);
export const getMetricsTableDataSorted = createSelector([getMetricsTableDataFiltered, getMetricsTableSort], sortFunc);
export const getMetricsTableDataSortedPaged = createSelector(
    [getMetricsTableDataSorted, getMetricsTablePage, getMetricsRowsPerPage],
    pageFunc);

export const getShortcodesTableDataFiltered = createSelector([getShortcodesTableData, getShortcodesTableFilters], filterFunc);
export const getShortcodesTableDataSorted = createSelector([getShortcodesTableDataFiltered, getShortcodesTableSort], sortFunc);
export const getShortcodesTableDataSortedPaged = createSelector(
    [getShortcodesTableDataSorted, getShortcodesTablePage, getShortcodesRowsPerPage],
    pageFunc);

export const getRewardsTableTotalRows = createSelector([getRewardsTableDataFiltered], (rewards) => rewards.length);
export const getMetricsTableTotalRows = createSelector([getMetricsTableDataFiltered], (metrics) => metrics.length);
export const getShortcodesTableTotalRows = createSelector([getShortcodesTableDataFiltered], (shortcodes) => shortcodes?.length);
export const getPartnersTableTotalRows = createSelector([getPartnersTableDataFiltered], (partners) => partners?.length);
