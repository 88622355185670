import { DATE_FORMAT } from 'app/constants/dateConstants';
import { RootState } from 'app/store';
import Datepicker from 'components/Datepicker/Datepicker';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMetricsDateFilter } from 'state/campaign/campaignSlice';
import styles from './CampaignMetricsDateFilter.module.scss';

export default function CampaignMetricsDateFilter(): React.ReactElement {
  const date = useSelector<RootState, { from: string | null; to: string | null }>(
    (state) => state.campaign.metricsFilters
  );

  const dispatch = useDispatch();

  const dateFrom = date.from ? parseISO(date.from) : null;
  const dateTo = date.to ? parseISO(date.to) : null;

  return (
    <div className={styles.root}>
      <div className={styles.dateBlock}>
        <span className={styles.dateLabel}>From</span>
        <div className={styles.dateInput}>
          <Datepicker
            value={dateFrom}
            maxDate={dateTo}
            onChange={(date) => {
              const value = date ? format(date, DATE_FORMAT) : null;
              dispatch(setMetricsDateFilter({ from: value }));
            }}
          />
        </div>
      </div>
      <div className={styles.dateBlock}>
        <span className={styles.dateLabel}>To</span>
        <div className={styles.dateInput}>
          <Datepicker
            value={dateTo}
            minDate={dateFrom}
            onChange={(date) => {
              const value = date ? format(date, DATE_FORMAT) : null;
              dispatch(setMetricsDateFilter({ to: value }));
            }}
          />
        </div>
      </div>
    </div>
  );
}
